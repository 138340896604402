import React from "react";
import { useLocalization } from "../../../hooks/useLocalization";
import Text from "../../atoms/Text";
import { ResultHeaderContainer } from "./styles";

const ResultHeader = () => {
  const translation = useLocalization();
  return (
    <ResultHeaderContainer>
      <Text
        content={translation.result_header_test_result}
        fontSize="2vh"
        // fontSize="16px"
        fontWeight="700"
      />
    </ResultHeaderContainer>
  );
};

export default React.memo(ResultHeader);
