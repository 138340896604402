import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 5.729166666666667vh;
  max-height: 110px;
  /* height: 110px; */
`;

export const TapTwoContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TapContainer = styled.div`
  width: 50%;
  height: 4.0625vh;
  max-height: 78px;
  /* height: 78px; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const TapListContainer = styled.div`
  /* margin-top: 22px; */
  /* height: 20px; */
  position: relative;
  margin-top: 1.1458333333333335vh;
  width: 100%;
  height: 1.0416666666666667vh;
  max-height: 20px;
  border-bottom: 0.20833333333333334vh solid #666;
`;

export const TapUnderLine = styled.div<{ active: boolean }>`
  position: absolute;
  width: 50%;
  height: 1.0416666666666667vh;
  max-height: 20px;
  left: ${(props) => (props.active ? "50%" : "0")};
  transition: 0.3s ease-in-out;
  bottom: 0;
  background-color: #fff;
`;
