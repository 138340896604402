export default {
  food: "Food",
  exercise: "Exercise",
  rest: "Sleep & Rest",
  mental: "Mind Control",
  tips_hr1_food_title: "1. Fruits and vegetables",
  tips_hr1_food_t1:
    "It is recommended to eat more than five times a day as if you are eating. Fruits and vegetables are high in nutrients and fiber and low in calories, and eating a lot reduces the risk of heart disease, stroke, and high blood pressure. In particular, green and yellow vegetables and fruits are good, and high moisture is good for your health, and it is better to eat them as they are than juice.",
  tips_hr2_food_title: "2. Grain",
  tips_hr2_food_t1:
    "There are many complex carbohydrates, vitamins, minerals, and fiber in unpolished brown rice, which reduces the risk of cardiovascular disease. Unpolished brown rice is also beneficial for weight control as it helps reduce bad cholesterol and causes satiety after meals. It is recommended to consume at least 25 grams per day.",
  tips_hr3_food_title: "3. Low-fat Meat",
  tips_hr3_food_t1:
    "Choose lean meats over high-fat meats to reduce your intake of saturated and trans fats, which can increase bad cholesterol and negatively affect blood vessels. Especially eating a lot of fast food can have a negative impact on cardiovascular health. Trans fatty acids are also found in snacks made with hydrogenated oils, as well as in margarine and shortening. Milk also contains a significant amount of saturated fat, so low-fat or skim milk is recommended.",
  tips_hr1_exercise_t1:
    "Exercise reduces the risk of cardiovascular disease. Those who don't exercise have a two-fold higher incidence of cardiovascular disease than those who do. Regular exercise at a moderate intensity level can decrease the risk of cardiovascular disease. The best exercises for training the heart are aerobic exercises such as brisk walking, jogging, cycling, and swimming. Starting with a few minutes of low-intensity exercise each day and gradually increasing the time and intensity is recommended.",
  tips_rep1_food_title: "1. Tomato",
  tips_rep1_food_t1:
    "Tomatoes contain high levels of lycopene, which gives them their red color. This compound has a strong anti-cancer effect, protects the lungs from various air pollutants, and helps improve lung function.",
  tips_rep2_food_title: "2. Bellflower root",
  tips_rep2_food_t1:
    "Bellflower root contains a large amount of saponin, which increases mucus secretion in the respiratory tract, helping to protect the lungs from various air pollutants. It is also known to be good for reducing coughs and phlegm and lowering fever, and has been widely used not only as a food but also as a traditional herbal medicine.",
  tips_rep3_food_title: "3. Ginger",
  tips_rep3_food_t1:
    "Ginger contains a high amount of gingerol, which has excellent nicotine detoxification effects and helps to remove phlegm that accumulates in the bronchi. It promotes lung cell growth and is known as a good food for the lungs. It also contains abundant anti-inflammatory ingredients that enhance the lung's ability to remove pollutants and support lung health.",
  tips_rep1_exercise_t1:
    "Regular aerobic exercise can help maintain lung health. If you sweat enough to dampen your back, it's a good sign. Slow jogging, fast walking, and swimming are good aerobic exercises for lung health.",
  tips_rep1_exercise_t2:
    "If it is not possible to do continuous exercise for 20-30 minutes due to shortness of breath, discomfort in the legs, or other symptoms, it is recommended to do intermittent exercise at a special intensity for a short period of time. ",
  tips_psi1_food_title: "1. Getting Nutrients",
  tips_psi1_food_t1:
    "Vitamin B1 is one of the nutrients that is involved in physical fatigue. When there is a deficiency of vitamin B1, it can have a negative impact on the body's organs and lead to symptoms such as fatigue, loss of appetite, and muscle weakness. Therefore, it is important to consistently consume vitamin B1. Since the food content of vitamin B1 is relatively low, it is recommended to supplement it through nutritional supplements.",
  tips_psi2_food_title: "2. Caution in consuming caffeine",
  tips_psi2_food_t1:
    "Many people turn to caffeine consumption instead of addressing the fundamental treatment of fatigue. However, since caffeine does not fundamentally alleviate fatigue, people tend to continue consuming more of it. Especially when caffeine is consumed after the afternoon, it can prevent deep sleep. Caution is needed since caffeine consumption can lead to addiction, as well as headaches and anxiety.",
  tips_psi3_food_title: "3. Adequate Water Intake",
  tips_psi3_food_t1:
    "If you are still feeling fatigued despite getting enough rest, try paying attention to your water intake. Water makes up 70% of the human body, and if there is not enough of it, metabolic activity decreases and various bodily functions are impaired. Water serves as the basis for bodily fluids including blood. However, if you do not drink enough water, your blood can become thick and your heart has to work harder to pump blood to all parts of your body, which can easily lead to fatigue.",
  tips_psi1_rest_t1:
    "Getting enough sleep is very important for recovering decreased energy levels and concentration. It's important to maintain a regular sleep schedule whenever possible. Short naps can help alleviate fatigue, but naps longer than 30 minutes should be avoided as they can make it difficult to fall asleep at night. When engaging in daily activities, it's important to take frequent short breaks to maintain a balance between rest and activity. Avoid spending long periods of time lying in bed and resting excessively as it can actually increase feelings of weakness.",
  tips_msi1_mindset_t1:
    "Many people smoke or make loud noises as a way to relieve stress. However, if one's work efficiency decreases and their mind becomes uncomfortable due to a hangover, in the long run, their immune system is weakened and their health deteriorates, and substance dependence (alcohol, tobacco, drugs, etc.) becomes a heavier stressor. Rather than relying on temporary comfort from substances, it is best to actively accept stress, recognize one's abilities and limits, and find appropriate coping methods within them.",
  tips_msi1_exercise_title: "1.  Shoulder Muscles",
  tips_msi1_exercise_t1:
    "- Lift your shoulders up as high as you can and hold for 10 seconds.",
  tips_msi1_exercise_t2:
    "- Feel the stretch along the neck and back of the head, and the tension around the shoulders.",
  tips_msi1_exercise_t3:
    "- Focus on the sensation of relaxing as you slowly lower your shoulders down for 20 seconds.",
  tips_msi1_exercise_t4: "- Enjoy the warm, relaxed feeling.",
  tips_msi2_exercise_title: "2. Neck Muscles",
  tips_msi2_exercise_t1:
    "- Lean your head and back against the chair backrest and press your chin towards your chest for 10 seconds.",
  tips_msi2_exercise_t2:
    "- Focus on the feeling of tension spreading from the back of your neck to your head.",
  tips_msi2_exercise_t3:
    "- Relax your muscles slowly for 20 seconds while resting your head comfortably against the chair backrest.",
  tips_msi2_exercise_t4: "- Enjoy the comfortable feeling.",
  tips_msi3_exercise_title: "3. Abdominal muscles",
  tips_msi3_exercise_t1:
    "- Flatten and tighten the abdominal area to tense the abdominal muscles and hold for 10 seconds.",
  tips_msi3_exercise_t2:
    "- Focus on the feeling of tension around the abdominal area that spreads throughout the body.",
  tips_msi3_exercise_t3:
    "- Release the tension slowly for 20 seconds, relaxing the abdominal muscles and expanding the abdominal area.",
  tips_msi3_exercise_t4:
    "- Breathe by inflating the abdomen instead of the chest.",

  // result
  ////////////////////////////////////////////////////////////

  result_header_test_result: "Results",
  result_healthy_range: "Healthy Range",
  result_comment_hr_good:
    "Your heart rate is # beats/min, which is within the #healthy range.#",
  result_comment_hr_normal:
    "Your heart rate is # beats/min, which is within the #normal range.#",
  result_comment_hr_bad:
    "Your heart rate is # beats/min, which is within the #dangerous range.#",
  result_comment_rep_good:
    "Your respiration rate is # breaths/min, which is within the #healthy range.#",
  result_comment_rep_normal:
    "Your respiration rate is # breaths/min, which is within the #normal range.#",
  result_comment_rep_bad:
    "Your respiration rate is # breaths/min, which is within the #dangerous range.#",
  result_comment_psi_good:
    "Your physical stress index is #, which is within the #healthy range.#",
  result_comment_psi_normal:
    "Your physical stress index is #, which is within the #normal range.#",
  result_comment_psi_bad:
    "Your physical stress index is #, which is within the #dangerous range.#",
  result_comment_msi_good:
    "Your mental stress index is #, which is within the #healthy range.#",
  result_comment_msi_normal:
    "Your mental stress index is #, which is within the #normal range.#",
  result_comment_msi_bad:
    "Your mental stress index is #, which is within the #dangerous range.#",
};
