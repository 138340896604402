import { CarouselContainer } from "./styles";
import React, { memo } from "react";
import Carousel from "../../molecules/Carousel";
import { registDragEvent } from "../../../utils/registDragEvent";
import { TTapBody } from "../../../type/tapBody.type";

export interface ITapBody {
  width: number;
  currentIdx: number;
  transX: number;
  bodyArr: { food: TTapBody[]; exercise: TTapBody[] };
  tipsIndex: { food: number; exercise: number };
  handleDragChange: (deltaX: number) => void;
  handleDragEnd: (deltaX: number) => void;
  handleTouchStart: (e: React.TouchEvent) => void;
  handleTouchMove: (e: React.TouchEvent) => void;
  handleTouchEnd: (e: React.TouchEvent) => void;
}

const TapBody = React.forwardRef<HTMLDivElement, ITapBody>(
  (
    {
      width,
      transX,
      currentIdx,
      bodyArr,
      tipsIndex,
      handleDragChange,
      handleDragEnd,
      handleTouchStart,
      handleTouchMove,
      handleTouchEnd,
    },
    ref
  ) => {
    return (
      <>
        <div ref={ref} style={{ overflow: "hidden", width: "100%" }}>
          <CarouselContainer
            currentIndex={currentIdx}
            width={width}
            transX={transX}
            // Mouse Drag
            {...registDragEvent({
              onDragChange: (deltaX) => handleDragChange(deltaX),
              onDragEnd: (deltaX) => handleDragEnd(deltaX),
            })}
            // Touch
            onTouchStart={(e: React.TouchEvent) => handleTouchStart(e)}
            onTouchMove={(e: React.TouchEvent) => handleTouchMove(e)}
            onTouchEnd={(e: React.TouchEvent) => handleTouchEnd(e)}
          >
            <Carousel
              currentIdx={currentIdx}
              body={bodyArr.food[tipsIndex.food]}
            />
            <Carousel
              currentIdx={currentIdx}
              body={bodyArr.exercise[tipsIndex.exercise]}
            />
          </CarouselContainer>
        </div>
      </>
    );
  }
);

export default memo(TapBody);
