import styled from "styled-components";

export const StyledText = styled.span`
  display: inline-block;
  color: #fff;
  margin: ${(props) => props.style?.margin};
  padding: ${(props) => props.style?.padding};
  opacity: ${(props) => (props.style?.opacity ? props.style?.opacity : "1")};
  user-select: none;
`;
