import styled from "styled-components";

export const ResultHeaderContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 5.5vh;
  /* height: 44px; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  z-index: 3;
`;
