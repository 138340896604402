import styled from "styled-components";

export const CarouselContainer = styled.div<{
  currentIndex: number;
  width: number;
  transX: number;
}>`
  display: flex;
  /* flex-direction: row; */
  width: 200%;
  transform: ${(props) =>
    `translateX(${-props.currentIndex * props.width + props.transX}px)`};
  transition: ${(props) =>
    `transform ${props.transX ? 0 : 300}ms ease-in-out 0s`};
`;
