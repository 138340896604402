import styled from "styled-components";

export const ResultTemplateContainer = styled.div`
  width: 100vw;
  /* width: 375px; */
  height: 83.375vh;
  /* height: 667px; */
  min-height: 667px;
  overflow-y: auto;
`;
