import { ArrowContainer, Container, PageCountContainer } from "./styles";
import * as Svg from "../../icons/index";
import Text from "../../atoms/Text";
import { memo } from "react";

interface INextPrev {
  tipsIndex: number;
  tipsLength: number;
  contentIdxPlus?: (e: React.MouseEvent<HTMLDivElement>) => void;
  contentIdxMinus?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const NextPrev = ({
  tipsLength,
  tipsIndex,
  contentIdxPlus,
  contentIdxMinus,
}: INextPrev) => {
  return (
    <Container>
      {tipsIndex - 1 !== 0 ? (
        <ArrowContainer onClick={contentIdxMinus}>
          <Svg.LeftArrow style={{ width: "4.166666666666667vh" }} />
        </ArrowContainer>
      ) : (
        <div
          style={{
            width: "8.333333333333334vh",
          }}
        />
      )}
      <PageCountContainer>
        <Text
          content={`${tipsIndex} / ${tipsLength}`}
          fontSize="3.125vh"
          fontWeight="900"
          customStyle={{ letterSpacing: "-0.052083333333333336vh" }}
        />
      </PageCountContainer>
      {tipsIndex !== tipsLength ? (
        <ArrowContainer onClick={contentIdxPlus}>
          <Svg.RightArrow style={{ width: "4.166666666666667vh" }} />
        </ArrowContainer>
      ) : (
        <div style={{ width: "8.333333333333334vh" }} />
      )}
    </Container>
  );
};

export default memo(NextPrev);
