import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import * as Pages from "./pages";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<Navigate replace to={`/tip/hr/ko`} />} />
        <Route path="/result/:query" element={<Pages.ResultPage />} />
        <Route path={`/tip/:category/:lang`} element={<Pages.HrTipPage />} />
        <Route path="*" element={<Navigate replace to={`/tip/hr/ko`} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
