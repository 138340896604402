import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { currentIndex } from "../store/currentIndex";
import { inrange } from "../utils/calcEvent";
import { useLocalization } from "./useLocalization";
import { langState } from "../store/localization";
import { useParams } from "react-router-dom";
import { TTipsData } from "../type/tapBody.type";

export interface IuseCarousel {
  initWidth?: number;
}

const useTap = (
  { initWidth = 0 }: IuseCarousel = {
    initWidth: 0,
  }
) => {
  const translation = useLocalization();
  const [currentIdx, setCurrentIdx] = useRecoilState(currentIndex);
  const [transX, setTransX] = useState(0);
  const [touch, setTouch] = useState(0);
  const carouselRef = useRef<HTMLDivElement>(null);
  const [{ width }, setCarouselSize] = useState({
    width: initWidth,
  });
  const [lang, setLang] = useRecoilState(langState);
  const [tipsIndex, setTipsIndex] = useState({ food: 0, exercise: 0 });
  const params = useParams();
  const pathName = params.category ? params.category : "hr";

  useEffect(() => {
    if (params.lang) setLang({ lang: params.lang });
  }, [params]);

  const bodyArr: TTipsData = {
    hr: {
      food: [
        {
          title: translation.tips_hr1_food_title,
          text1: translation.tips_hr1_food_t1,
          img: `${process.env.PUBLIC_URL}/assets/img/img_heartrate_food_1.webp`,
        },
        {
          title: translation.tips_hr2_food_title,
          text1: translation.tips_hr2_food_t1,
          img: `${process.env.PUBLIC_URL}/assets/img/img_heartrate_food_2.webp`,
        },
        {
          title: translation.tips_hr3_food_title,
          text1: translation.tips_hr3_food_t1,
          img: `${process.env.PUBLIC_URL}/assets/img/img_heartrate_food_3.webp`,
        },
      ],
      exercise: [
        {
          text1: translation.tips_hr1_exercise_t1,
          img: `${process.env.PUBLIC_URL}/assets/img/img_heartrate_exer_1.webp`,
        },
      ],
    },
    rep: {
      food: [
        {
          title: translation.tips_rep1_food_title,
          text1: translation.tips_rep1_food_t1,
          img: `${process.env.PUBLIC_URL}/assets/img/img_breath_food_1.webp`,
        },
        {
          title: translation.tips_rep2_food_title,
          text1: translation.tips_rep2_food_t1,
          img: `${process.env.PUBLIC_URL}/assets/img/img_breath_food_2.webp`,
        },
        {
          title: translation.tips_rep3_food_title,
          text1: translation.tips_rep3_food_t1,
          img: `${process.env.PUBLIC_URL}/assets/img/img_breath_food_3.webp`,
        },
      ],
      exercise: [
        {
          text1: translation.tips_rep1_exercise_t1,
          text2: translation.tips_rep1_exercise_t2,
          enter: 1,
          img: `${process.env.PUBLIC_URL}/assets/img/img_breath_exer_1.webp`,
        },
      ],
    },
    psi: {
      food: [
        {
          title: translation.tips_psi1_food_title,
          text1: translation.tips_psi1_food_t1,
          img: `${process.env.PUBLIC_URL}/assets/img/img_physical_food_1.webp`,
        },
        {
          title: translation.tips_psi2_food_title,
          text1: translation.tips_psi2_food_t1,
          img: `${process.env.PUBLIC_URL}/assets/img/img_physical_food_2.webp`,
        },
        {
          title: translation.tips_psi3_food_title,
          text1: translation.tips_psi3_food_t1,
          img: `${process.env.PUBLIC_URL}/assets/img/img_physical_food_3.webp`,
        },
      ],
      exercise: [
        {
          text1: translation.tips_psi1_rest_t1,
          img: `${process.env.PUBLIC_URL}/assets/img/img_physical_rest_1.webp`,
        },
      ],
    },
    msi: {
      food: [
        {
          text1: translation.tips_msi1_mindset_t1,
          img: `${process.env.PUBLIC_URL}/assets/img/img_metal_care_1.webp`,
        },
      ],
      exercise: [
        {
          title: translation.tips_msi1_exercise_title,
          text1: translation.tips_msi1_exercise_t1,
          text2: translation.tips_msi1_exercise_t2,
          text3: translation.tips_msi1_exercise_t3,
          text4: translation.tips_msi1_exercise_t4,
          img: `${process.env.PUBLIC_URL}/assets/img/img_metal_exer_1.webp`,
        },
        {
          title: translation.tips_msi2_exercise_title,
          text1: translation.tips_msi2_exercise_t1,
          text2: translation.tips_msi2_exercise_t2,
          text3: translation.tips_msi2_exercise_t3,
          text4: translation.tips_msi2_exercise_t4,
          img: `${process.env.PUBLIC_URL}/assets/img/img_metal_exer_2.webp`,
        },
        {
          title: translation.tips_msi3_exercise_title,
          text1: translation.tips_msi3_exercise_t1,
          text2: translation.tips_msi3_exercise_t2,
          text3: translation.tips_msi3_exercise_t3,
          text4: translation.tips_msi3_exercise_t4,
          img: `${process.env.PUBLIC_URL}/assets/img/img_metal_exer_3.webp`,
        },
      ],
    },
  };

  useEffect(() => {
    if (!carouselRef.current) return;
    const carouselRect = carouselRef.current.getBoundingClientRect();

    setCarouselSize({
      width: carouselRect.width,
    });
  }, [carouselRef]);

  const changeTap = useCallback((e: React.MouseEvent | React.TouchEvent) => {
    const { id } = e.currentTarget;
    if (id === "food") {
      setCurrentIdx(1);
    } else if (id === "exercise") {
      setCurrentIdx(0);
    }
  }, []);

  const handleDragChange = (deltaX: number) => {
    setTransX(inrange(deltaX, -width, width));
  };

  const handleDragEnd = (deltaX: number) => {
    // const maxIndex = bodyArr.length - 1;
    const maxIndex = 1;
    if (deltaX < -150) setCurrentIdx(inrange(currentIdx + 1, 0, maxIndex));
    if (deltaX > 150) setCurrentIdx(inrange(currentIdx - 1, 0, maxIndex));

    setTransX(0);
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouch(e.changedTouches[0].pageX);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    const deltaX = e.touches[0].pageX - touch;
    setTransX(inrange(deltaX, -width, width));
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    // const maxIndex = bodyArr.length - 1;
    const maxIndex = 1;
    if (transX < -100) setCurrentIdx(inrange(currentIdx + 1, 0, maxIndex));
    if (transX > 100) setCurrentIdx(inrange(currentIdx - 1, 0, maxIndex));
    setTransX(0);
  };

  const contentIdxPlus = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (currentIdx === 0) {
        setTipsIndex((prev) => {
          return { ...prev, food: prev.food + 1 };
        });
      } else {
        setTipsIndex((prev) => {
          return { ...prev, exercise: prev.exercise + 1 };
        });
      }
    },
    [tipsIndex, currentIdx]
  );

  const contentIdxMinus = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (currentIdx === 0) {
        setTipsIndex((prev) => {
          return { ...prev, food: prev.food - 1 };
        });
      } else {
        setTipsIndex((prev) => {
          return { ...prev, exercise: prev.exercise - 1 };
        });
      }
    },
    [tipsIndex, currentIdx]
  );

  return {
    ref: carouselRef,
    width,
    currentIdx,
    transX,
    bodyArr: bodyArr[pathName],
    tipsIndex,
    changeTap,
    handleDragChange,
    handleDragEnd,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    contentIdxPlus,
    contentIdxMinus,
  };
};

export default useTap;
