import styled from "styled-components";
import { IStyledButton } from ".";

export const StyledButton = styled.button<IStyledButton>`
  display: flex;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: ${(props) =>
    props.style?.borderRadius ? props.style?.borderRadius : "40px"};
  align-items: center;
  outline: none;
  border: none;
  background-color: ${(props) =>
    props.style?.backgroundColor
      ? props.style?.backgroundColor
      : props.theme.white};
  background-image: ${(props) =>
    props.backgroundImage ? `url("${props.backgroundImage}")` : ""};
  background-position: center;
  background-repeat: no-repeat;
  background-size: ${(props) => props.style?.width};
  color: ${(props) =>
    props.style?.color ? props.style?.color : props.theme.white};
  width: ${(props) => props.style?.width};
  height: ${(props) => props.style?.height};
  font-size: ${(props) =>
    props.style?.fontSize ? props.style?.fontSize : "14px"};
  font-weight: ${(props) =>
    props.style?.fontWeight ? props.style?.fontWeight : "700"};
`;
