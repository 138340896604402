export const calcRep = (rep: number) => {
  let reps = rep;
  let status = "";
  if (reps < 4) reps = 4;
  if (reps > 40) reps = 40;
  if (6 <= reps && reps <= 18) {
    status = "good";
  } else if ((19 <= reps && reps < 21) || reps < 6) {
    status = "normal";
  } else {
    status = "bad";
  }
  return { rep: reps, status: status };
};

export const calcSi = (_si: number, whatSi: string) => {
  let status = "";
  let si = _si;
  if (0 > si) si = 0;
  if (si >= 100) si = 100;
  if (33 <= si && si <= 65) {
    status = "good";
  } else if (15 <= si && si <= 84) {
    status = "normal";
  } else {
    status = "bad";
  }
  return { [whatSi]: si, status: status };
};

export const percentFc = (x: string | number, min: number, max: number) => {
  return (100 / (max - min)) * (Number(x) - min);
};
