import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";

export const GlobalStyle = createGlobalStyle`
    ${reset}
    * {
        font-family: "Pretendar", sans-serif !important;
        box-sizing: border-box;
    }

    body {
        margin: 0;
        padding: 0;
        background-color: #000;
        color: #fff;
        user-select: none;
    }
`;
