import { CenterContainer, Container } from "./styles";
import TapBody from "../../organisms/TapBody";
import TwoTap from "../../molecules/TwoTap";
import { memo } from "react";
import NextPrev from "../../molecules/NextPrev";
import React from "react";
import { useLocalization } from "../../../hooks/useLocalization";
import { TTapBody } from "../../../type/tapBody.type";
import { useParams } from "react-router-dom";

interface IHrTipTemplate {
  ref: React.RefObject<HTMLDivElement>;
  width: number;
  currentIdx: number;
  transX: number;
  tipsIndex: { food: number; exercise: number };
  bodyArr: { food: TTapBody[]; exercise: TTapBody[] };
  changeTap?: (e: React.MouseEvent | React.TouchEvent) => void;
  handleDragChange: (deltaX: number) => void;
  handleDragEnd: (deltaX: number) => void;
  handleTouchStart: (e: React.TouchEvent) => void;
  handleTouchMove: (e: React.TouchEvent) => void;
  handleTouchEnd: (e: React.TouchEvent) => void;
  contentIdxPlus?: (e: React.MouseEvent<HTMLDivElement>) => void;
  contentIdxMinus?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const HrTipTemplate = React.forwardRef<HTMLDivElement, IHrTipTemplate>(
  (
    {
      width,
      transX,
      currentIdx,
      bodyArr,
      tipsIndex,
      changeTap,
      handleDragChange,
      handleDragEnd,
      handleTouchStart,
      handleTouchMove,
      handleTouchEnd,
      contentIdxPlus,
      contentIdxMinus,
    },
    ref
  ) => {
    const { category } = useParams();
    const translation = useLocalization();
    let tapName = [translation.food, translation.exercise];
    if (category === "psi") tapName = [translation.food, translation.rest];
    if (category === "msi")
      tapName = [translation.mental, translation.exercise];

    return (
      <Container>
        <CenterContainer>
          {/* <Header title="심박수에 좋은 팁" /> */}
          <TwoTap
            title={[tapName[0], tapName[1]]}
            currentIdx={currentIdx}
            changeTap={changeTap}
          />
          <TapBody
            ref={ref}
            width={width}
            transX={transX}
            currentIdx={currentIdx}
            bodyArr={bodyArr}
            tipsIndex={tipsIndex}
            handleDragChange={handleDragChange}
            handleDragEnd={handleDragEnd}
            handleTouchStart={handleTouchStart}
            handleTouchMove={handleTouchMove}
            handleTouchEnd={handleTouchEnd}
          />
          <NextPrev
            tipsLength={
              currentIdx === 0 ? bodyArr.food.length : bodyArr.exercise.length
            }
            tipsIndex={
              currentIdx === 0 ? tipsIndex.food + 1 : tipsIndex.exercise + 1
            }
            contentIdxPlus={contentIdxPlus}
            contentIdxMinus={contentIdxMinus}
          />
        </CenterContainer>
      </Container>
    );
  }
);

export default memo(HrTipTemplate);
