export default {
  food: "음식",
  exercise: "운동",
  rest: "수면과 휴식",
  mental: "마음 관리",
  tips_hr1_food_title: "1. 과일과 야채",
  tips_hr1_food_t1:
    "식사하듯이 하루에 다섯 번 이상 먹는 것을 원칙으로 한다. 과일과 야채에는 영양소와 섬유소가 많고 칼로리가 적으며, 많이 먹으면 심장병, 뇌졸중, 고혈압의 위험도가 줄어든다. 특히 녹황색 채소나 과일이 좋으며 수분이 많은 것이 건강에 도움이 되고 쥬스보다는 있는 그대로 먹는 것이 좋다.",
  tips_hr2_food_title: "2. 곡물",
  tips_hr2_food_t1:
    "복합 탄수화물, 비타민, 미네랄, 섬유소 등이 많아서 심혈관질환의 위험도를 감소시킨다. 도정을 하지 않은 현미류가 좋으며 나쁜 콜레스테롤을 감소시키고 식사 후 포만감이 지속되기 때문에 체중조절에 도움이 된다. 하루에 25 gm 이상 권장된다.",
  tips_hr3_food_title: "3. 저지방 육류",
  tips_hr3_food_t1:
    "지방이 많은 육류의 섭취를 줄이고 가능하면 살코기를 먹도록 한다.포화지방을 많이 먹게되면 나쁜 콜레스테롤이 상승한다. 음식을 튀기게 되면 트랜스지방산이 생성되는데 이것 역시 나쁜 콜레스테롤을 상승시켜서 혈관에 나쁜 영향을 미치게 된다. 특히 같은 기름으로 여러 번 튀기게 되면 나쁜 효과는 더욱 증대된다. 즉 패스트푸드를 많이 먹는 것은 심혈관에 좋지 않은 영향을 줄 수 있다는 것이다. 수소화 처리된 기름을 사용한 과자 등에도 트랜스 지방산이 많으며 마가린, 쇼팅 등에도 많이 들어있다. 우유에도 포화지방이 상당량 있으므로 저지방 우유나 탈지 우유가 권장된다.",
  tips_hr1_exercise_t1:
    "운동은 심장질환의 위험을 감소시킨다. 운동을 하지 않는 사람은 운동을 하는 사람에 비하여 심장질환의 발병률이 2배 정도 높다. 강도가 약한 정도부터 시작하여 중간 정도의 운동을 규칙적으로 한다면 심장질환의 위험을 감소시킬 수 있다. 심장을 단련하기 위한 가장 좋은 운동은 빠른 속도로 걷기, 조깅, 자전거 타기, 수영과 같은 유산소 운동이다.  매일 몇 분간 약한 강도부터 중간 정도의 강도로 시작하고, 차근차근 시간과 강도를 증가시키는 것이 좋다. ",
  tips_rep1_food_title: "1. 토마토",
  tips_rep1_food_t1:
    "토마토에는 붉은색을 나타내는 라이코펜 성분이 다량 함유되어 있으며 이 성분은 함암작용 효과가 뛰어나며 각종 대기 오염으로부터 폐를 보호해 주며 폐 기능 향상에 도움을 준다.",
  tips_rep2_food_title: "2. 도라지",
  tips_rep2_food_t1:
    "사포닌 성분이 다량 함유되어 있어 호흡할 때 공기가 지나가는 길인 기도의 점액분비물을 늘려 각종 대기 오염으로부터 폐를 보호해 준다. 또한 기침과 가래를 줄이고 열을 내리는데 좋다고 알려져 있어 예전부터 음식뿐 아니라 민간요법 약재로도 널리 사용되었다.",
  tips_rep3_food_title: "3. 생강",
  tips_rep3_food_t1:
    "생강에는 진저 성분이 다량 함유되어 있으며 이 성분은 니코틴 해독 효과와 기관지에 쌓여있는 가래를 제거해 주는 효과가 뛰어나 폐 세포를 촉진시켜주어 폐에 좋은 음식으로 알려져 있다. 또한 항염증 성분을 풍부하게 포함하고 있어 폐의 오염 물질 제거 기능을 향상시켜 폐 건강에 도움을 준다.",
  tips_rep1_exercise_t1:
    "유산소운동을 꾸준히 하면 폐 건강을 지킬 수 있다. 등에 땀이 촉촉하게 날 정도면 무난하다. 천천히 달리거나 빠르게 걷기, 수영 등이 폐 건강을 위해 좋은 유산소 운동이다. 운동은 저강도로 시간 길게 갖되 처음에는 단계를 나누어 실시한다.",
  tips_rep1_exercise_t2:
    "호흡곤란, 하지의 불편함, 그 이외의 다른 증상들로 인하여 20~30분의 지속적인 운동이 불가능할 경우 단시간의 특별화된 강도에서 간헐적인 운동(intermittent exercise)을 자주 실시한다. 운동 중간의 휴식을 반복하며, 휴식은 신체적 노력을 지속할 수 있도록 하는 효과를 얻을 수 있다.",
  tips_psi1_food_title: "1. 영양소 챙기기",
  tips_psi1_food_t1:
    "육체피로에 관여하는 영양소로 비타민 B1이 있다. 비타민 B1은 세포 영양대사에 관여하기 때문에 비타민 B1이 결핍되면 인체기관에 악영향을 끼칠 수 있고 피로감이나 식욕부진, 근무력증 등의 증상이 나타날 수 있다. 따라서 피로물질 축적을 막아 육체피로 회복과 식욕부진에 효과가 있는 비타민B1을 꾸준히 섭취해야 한다. 비타민 B1은 식품 함량이 낮은 편이기 때문에 영양제를 통해 보충해 주는 것이 좋다.",
  tips_psi2_food_title: "2. 카페인 섭취 주의",
  tips_psi2_food_t1:
    "피로의 원인을 찾아 근본적인 치료를 하려고 하기보다는 임시방편으로 카페인을 섭취하는 사람들이 많다. 그러나 카페인은 근본적으로 피로를 해소해주지는 못하므로 계속해서 더 섭취하게 된다. 특히 오후 시간 이후 카페인을 섭취하면 깊은 잠을 이루지 못하고 다음날 일어나서 피로감에 다시 커피를 마시는 상황이 반복되어 피로가 누적될 수 있다. 습관적인 카페인 섭취는 중독의 위험이 있고, 두통이나 불안, 가벼운 우울증을 유발할 수 있으니 주의가 필요하다.",
  tips_psi3_food_title: "3. 충분한 수분 섭취",
  tips_psi3_food_t1:
    "충분히 쉬었는데도 피로가 해소되지 않는다면 수분 섭취에 신경써보자. 수분은 신체의 70%를 차지하고 있다. 물이 부족하면 몸의 대사 작용이 줄고, 여러 가지 대응 능력도 떨어진다.물은 혈액을 비롯한 체액의 원료가 된다. 그런데 물을 많이 마시지 않으면 혈액이 걸쭉해져 심장이 세포 곳곳에 혈액을 보내기 위해 펌프질을 무리하게 해야 하기 때문에 쉽게 피로해진다. 물을 많이 마셔야 에너지가 효과적으로 신체 이곳 저곳에 전달될 수 있다.",
  tips_psi1_rest_t1:
    "충분한 수면은 저하된 에너지 수준과 집중력을 회복하기 위해 매우 중요하다. 가능하면 취침과 기상 시간을 일정하게 유지해야 한다. 짧은 시간의 낮잠은 피로를 완화시키는데 도움이 된다. 그러나 낮잠 시간이 길어지면 밤시간에 숙면을 취하기가 어려우므로 30분 이상의 낮잠은 피해야 한다. 일상적인 활동을 시행할 때에도 자주 짧은 시간의 휴식을 취하여 휴식과 활동에 균형을 유지한다. 오랜 시간 침대에 누워 과도하게 휴식을 취하는 것은 오히려 허약감을 증가시키기 때문에 지양하는 것이 좋다.",
  tips_msi1_mindset_t1:
    "많은 사람이 ‘스트레스 해소’의 방법으로 줄담배를 피우거나 폭음을 하기도 한다. 그러나 숙취로 인해 업무 효율성이 떨어지고, 마음이 불편해지면서 장기적으로 면역력이 저하되고 건강이 악화되는 등 결국에는 물질의존(술, 담배, 약물 등) 자체가 더욱 가중된 스트레스로 돌아오게 된다. 일시적인 위안을 주는 물질에 의존하기보다는 능동적으로 스트레스를 수용하여 자신의 능력과 한계를 인정하고 그 안에서 적절한 대처방법을 찾는 것이 최선이다.",
  tips_msi1_exercise_title: "1.  어깨 근육",
  tips_msi1_exercise_t1:
    "- 어깨를 올릴 수 있는 한 최대로 올린 상태에서 10초 동안 유지한다.",
  tips_msi1_exercise_t2:
    "- 등을 따라 목과 머리 뒤로 뻗쳐가는 느낌, 어깨 주위의 긴장감을 느낀다.",
  tips_msi1_exercise_t3:
    "- 20초 동안 서서히 아래로 어깨를 늘어뜨려 이완되었을 때의 감각에 집중한다.",
  tips_msi1_exercise_t4: "- 따뜻하고 얼얼해지는 이완된 느낌을 즐긴다.",
  tips_msi2_exercise_title: "2. 목 근육",
  tips_msi2_exercise_t1:
    "- 머리와 등을 의자 등받이에 기대면서 턱을 가슴 쪽으로 붙여 10초간 유지한다.",
  tips_msi2_exercise_t2:
    "- 목 뒤쪽의 조이는 듯한 감각이 머리로 퍼져가는 느낌에 집중한다.",
  tips_msi2_exercise_t3:
    "- 20초간 서서히 힘을 빼면서 머리를 의자 등받이에 편안하게 기댄다.",
  tips_msi2_exercise_t4: "- 긴장했을 때와는 다른 편안한 느낌을 즐긴다.",
  tips_msi3_exercise_title: "3. 복부 근육",
  tips_msi3_exercise_t1:
    "- 복부 부위를 납작하고 단단하게 만들어 복부 근육을 긴장시킨 후 10초간 유지한다.",
  tips_msi3_exercise_t2:
    "- 근육을 긴장시킨 상태에서 어떻게 호흡하는지 잘 기억해둔다.",
  tips_msi3_exercise_t3:
    "- 20초간 천천히 배에서 힘을 빼면서 복부 근육을 이완하고 복부 부위를 내민다.",
  tips_msi3_exercise_t4:
    "- 가슴이 아니라 복부를 팽창했다가 수축시키며 호흡한다.",

  // result
  ////////////////////////////////////////////////////////////

  result_header_test_result: "테스트 결과",
  result_healthy_range: "정상 범위",
  result_comment_hr_good:
    "측정인의 현재 심박수는 #회/분으로 휴식기 심박수가 #안정 상태#입니다.",
  result_comment_hr_normal:
    "측정인의 현재 심박수는 #회/분으로 휴식기 심박수가 #안정 상태보다 낮습니다.#",
  result_comment_hr_bad:
    "측정인의 현재 심박수는 #회/분으로 휴식기 심박수가 #안정 상태보다 높습니다.#",
  result_comment_rep_good:
    "측정인의 현재 호흡수는 #회/분으로 정상 범위에 있는 #건강한 상태#입니다.",
  result_comment_rep_normal:
    "측정인의 현재 호흡수는 #회/분으로 #주의 상태#입니다.",
  result_comment_rep_bad:
    "측정인의 현재 호흡수는 #회/분으로 #위험한 상태#입니다.",
  result_comment_psi_good:
    "측정인의 현재 육체적 스트레스 지수는 #으로 정상 범위에 있는 #건강한 상태#입니다.",
  result_comment_psi_normal:
    "측정인의 현재 육체적 스트레스 지수는 #으로 #주의 상태#입니다.",
  result_comment_psi_bad:
    "측정인의 현재 육체적 스트레스 지수는 #으로 #위험한 상태#입니다.",
  result_comment_msi_good:
    "측정인의 현재 정신적 스트레스 지수는 #으로 정상 범위에 있는 #건강한 상태#입니다.",
  result_comment_msi_normal:
    "측정인의 현재 정신적 스트레스 지수는 #으로 #주의 상태#입니다.",
  result_comment_msi_bad:
    "측정인의 현재 정신적 스트레스 지수는 #으로 #위험한 상태#입니다.",
};
