import React from "react";
import Text from "../../atoms/Text";
import {
  BarContainer,
  Baritem,
  BarPaddingContainer,
  CircleInBar,
  GrayBox,
  NormalRangeContainer,
  NormalRangeLeft,
  NormalRangeRight,
  RangeContainer,
  ResultItemContainer,
  TextBox,
  TitleContainer,
} from "./styles";
import { theme } from "../../../styles/theme";
import { useLocalization } from "../../../hooks/useLocalization";

interface IResultItem {
  content: string;
  dataValue: number;
  rangeMin: number;
  rangeMax: number;
  comment: string;
  circleValue: string;
  status: string;
}

const ResultItem = ({
  content,
  dataValue,
  rangeMin,
  rangeMax,
  comment,
  circleValue,
  status,
}: IResultItem) => {
  const translation = useLocalization();

  return (
    <ResultItemContainer>
      <TitleContainer>
        <Text content={content} fontSize="2vh" fontWeight="700" />
        {/* <Text content={content} fontSize="16px" fontWeight="700" /> */}
      </TitleContainer>
      <BarPaddingContainer>
        <BarContainer>
          <RangeContainer dataPercent={circleValue}>
            <Text
              content={dataValue}
              fontSize="2.500vh"
              // fontSize="20px"
              fontWeight="700"
              color={theme.color[status]}
            />
          </RangeContainer>
          <Baritem />
          <GrayBox
            min={rangeMin}
            widthValue={rangeMin ? `${rangeMax - rangeMin * 1}` : "12.750vh"}
            // widthValue={rangeMin ? `${rangeMax - rangeMin * 1}` : "102px"}
          />
          <CircleInBar
            circleValue={circleValue}
            circleColor={theme.color[status]}
          />
          <NormalRangeContainer rangeValue={`${rangeMin}%`}>
            <NormalRangeLeft />
          </NormalRangeContainer>
          <Text
            content={translation.result_healthy_range}
            fontSize="1vh"
            // fontSize="8px"
            fontWeight="900"
            customStyle={{
              maxWidth: "5vh",
              textAlign: "center",
              position: "absolute",
              top: "230%",
              transform: "translateX(-50%)",
              left: `${(rangeMax + rangeMin) / 2}%`,
            }}
          />
          <NormalRangeRight rangeValue={`${rangeMax}%`} />
        </BarContainer>
      </BarPaddingContainer>
      <TextBox>
        <Text
          content={comment.split("#")[0]}
          fontSize="1.500vh"
          // fontSize="12px"
          fontWeight="400"
          customStyle={{ margin: "5.5vh 0.25vh 0.25vh 0" }}
          // customStyle={{ margin: "40px 3px 3px 0" }}
        />
        <Text
          content={dataValue}
          fontSize="1.500vh"
          // fontSize="12px"
          fontWeight="400"
          color={theme.color[status]}
          customStyle={{ marginRight: "0.250vh" }}
          // customStyle={{ marginRight: "2px" }}
        />
        <Text
          content={comment.split("#")[1]}
          fontSize="1.500vh"
          // fontSize="12px"
          fontWeight="400"
          customStyle={{ marginRight: "0.625vh" }}
        />
        <Text
          content={comment.split("#")[2]}
          fontSize="1.500vh"
          // fontSize="12px"
          fontWeight="400"
          color={theme.color[status]}
        />
        <Text
          content={comment.split("#")[3]}
          fontSize="1.500vh"
          // fontSize="12px"
          fontWeight="400"
        />
      </TextBox>
    </ResultItemContainer>
  );
};

export default React.memo(ResultItem);
