import styled from "styled-components";

export const ResultItemContainer = styled.div`
  width: 41.875vh;
  /* width: 335px; */
  height: 23.625vh;
  /* height: 189px; */
  border-radius: 2.5vh;
  /* border-radius: 20px; */
  background-color: #181818;
  margin-top: 2.5vh;
  /* margin-top: 20px; */
`;

export const TitleContainer = styled.div`
  width: 100%;
  padding: 2.5vh 2.5vh 2vh 2.5vh;
  /* padding: 20px 20px 16px 20px; */
  margin-bottom: 4.375vh;
  /* margin-bottom: 35px; */
`;

export const RangeContainer = styled.div<{ dataPercent: string }>`
  position: absolute;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: baseline;
  top: -400%;
  left: ${(props) => `${props.dataPercent}%`};
  transform: translateX(-50%);
`;

export const BarPaddingContainer = styled.div`
  padding: 0 2.5vh;
  /* padding: 0 20px; */
`;

export const BarContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
`;

export const Baritem = styled.div`
  width: 36.875vh;
  /* width: 295px; */
  height: 1vh;
  /* height: 8px; */
  background-color: #fff;
  border-radius: 1.087vh;
  /* border-radius: 8.7px; */
`;

export const CircleInBar = styled.div<{
  circleValue: string;
  circleColor: string;
}>`
  position: absolute;
  top: -50%;
  left: ${(props) => `${props.circleValue}%`};
  transform: translateX(-50%);
  width: 2.175vh;
  /* width: 17.4px; */
  height: 2.175vh;
  /* height: 17.4px; */
  border-radius: 50%;
  background-color: ${(props) => props.circleColor};
`;

export const NormalRangeContainer = styled.div<{ rangeValue?: string }>`
  position: absolute;
  top: 100%;
  left: ${(props) => (props.rangeValue ? props.rangeValue : "15%")};
`;

export const NormalRangeLeft = styled.div`
  position: absolute;
  width: 0.125vh;
  /* width: 1px; */
  height: 1vh;
  /* height: 8px; */
  background-color: #fff;
  top: calc(100% + 1.174vh);
  /* top: calc(100% + 9.39px); */
`;

export const NormalRangeRight = styled.div<{ rangeValue?: string }>`
  position: absolute;
  width: 0.125vh;
  /* width: 1px; */
  height: 1vh;
  /* height: 8px; */
  background-color: #fff;
  top: calc(100% + 1.174vh);
  /* top: calc(100% + 9.39px); */
  left: ${(props) => (props.rangeValue ? props.rangeValue : "50%")};
`;

export const GrayBox = styled.div<{
  min: number;
  widthValue: number | string;
}>`
  position: absolute;
  left: ${(props) => `${props.min}%`};
  width: ${(props) => (props.widthValue ? `${props.widthValue}%` : "12.750vh")};
  /* width: ${(props) =>
    props.widthValue ? `${props.widthValue}%` : "102px"}; */
  height: 1vh;
  /* height: 8px; */
  background-color: #666;
`;

export const TextBox = styled.div`
  padding: 0 1.25vh 0 2.5vh;
  /* padding: 0 10px 0 20px; */
`;
