export type TLocalization = {
  lang: string;
};

export const defaultLang: TLocalization = {
  lang: window.navigator.language.includes("ko")
    ? "ko"
    : window.navigator.language.includes("vi")
    ? "vi"
    : "en",
};
