export default {
  food: "đồ ăn",
  exercise: "tập thể dục",
  rest: "Nghỉ ngơi",
  mental: "Quản lý tâm trí",
  tips_hr1_food_title: "1. Trái cây và rau",
  tips_hr1_food_t1:
    "Thực hiện nguyên tắc ăn nhiều lần trong ngày, tối thiểu 5 lần mỗi ngày. Trái cây và rau có nhiều dưỡng chất và chất xơ, ít calo, ăn nhiều sẽ giảm nguy cơ bệnh tim, đột quỵ và huyết áp cao. Đặc biệt, rau củ màu xanh lá và trái cây có nhiều nước là tốt cho sức khỏe và nên ăn trái cây và rau sống thay vì uống nước hoa quả.",
  tips_hr2_food_title: "2. Ngũ cốc",
  tips_hr2_food_t1:
    "Nên ăn nhiều hơn 5 lần trong ngày giống như bữa ăn. Ngũ cốc chứa nhiều loại tinh bột phức, vitamin, khoáng chất, chất xơ,... giúp giảm nguy cơ mắc bệnh tim mạch. Lúa mì hạt nguyên cám tốt hơn khi không qua xử lý, giúp giảm cholesterol xấu và giữ cho bạn cảm thấy no sau bữa ăn nên rất có ích trong việc kiểm soát cân nặng. Nên ăn ít nhất 25 gam/ngày.",
  tips_hr3_food_title: "3. Thịt gia cầm ít chất béo",
  tips_hr3_food_t1:
    "Giảm tiêu thụ các loại thịt chứa nhiều chất béo và ăn thịt tươi sống nếu có thể. Ăn nhiều chất béo bão hòa sẽ làm tăng cholesterol xấu. Khi chiên nướng thực phẩm, axit béo trans được tạo ra, làm tăng cholesterol xấu và có ảnh hưởng xấu đến mạch máu. Ăn nhiều đồ ăn nhanh và bánh kẹo cũng gây ảnh hưởng không tốt đến mạch máu. Sữa cũng chứa một lượng đáng kể chất béo bão hòa, nên nên ăn sữa ít béo hoặc không béo.",
  tips_hr1_exercise_t1:
    "Hoạt động thể chất giảm nguy cơ mắc bệnh tim. Nguy cơ mắc bệnh tim của những người không tập thể dục gấp đôi so với những người tập thể dục. Nếu tập luyện thường xuyên với mức độ tập luyện trung bình từ mức độ thấp, sẽ giúp giảm nguy cơ mắc bệnh tim. Tập luyện vận động hô hấp như đi bộ nhanh, chạy bộ, đạp xe đạp, bơi là cách tốt nhất để rèn luyện trái tim. Bắt đầu với mức độ thấp và từ từ tăng thời gian và độ khó hàng ngày.",
  tips_rep1_food_title: "1. Cà chua",
  tips_rep1_food_t1:
    "Cà chua có chứa thành phần lycopeen có màu đỏ đặc trưng và thành phần này có tác dụng chống ung thư tốt và giúp bảo vệ phổi khỏi các tác động của ô nhiễm không khí, hỗ trợ cải thiện chức năng hô hấp của phổi.",
  tips_rep2_food_title: "2. Củ đậu xanh",
  tips_rep2_food_t1:
    "Củ đậu xanh chứa nhiều chất xơ và chất dinh dưỡng giúp cải thiện hệ tiêu hóa và hấp thụ chất dinh dưỡng. Nó cũng có tác dụng làm giảm nguy cơ mắc bệnh tiểu đường và bệnh tim mạch.",
  tips_rep3_food_title: "3. Gừng",
  tips_rep3_food_t1:
    "Gừng chứa thành phần gingerol, có tác dụng khử độc nicotine và giúp làm sạch đào thải chất nhầy trong phế quản, tăng cường sự hoạt động của tế bào phổi, được biết đến là một thực phẩm tốt cho phổi. Ngoài ra, gừng cũng chứa thành phần chống viêm phong phú, giúp tăng cường chức năng loại bỏ chất độc trong phổi, giúp cải thiện sức khỏe phổi.",
  tips_rep1_exercise_t1:
    "Bạn có thể duy trì sức khỏe phổi bằng việc thường xuyên tập thể dục có tác dụng aerobic. Nếu bạn ra mồ hôi ở lưng thì đó là mức độ vừa phải. Chạy bộ chậm, đi bộ nhanh, bơi lội,... là các loại thể dục có tác dụng tốt cho sức khỏe phổi. Tập thể dục nên bắt đầu ở mức độ thấp, tăng dần theo từng giai đoạn.",
  tips_rep1_exercise_t2:
    "Có thể đạt được hiệu quả giữ nỗ lực thể chất bằng cách lặp lại các thời điểm nghỉ trong khi tập luyện.",
  tips_psi1_food_title: "1. Đảm bảo dinh dưỡng",
  tips_psi1_food_t1:
    "Có chất dinh dưỡng B1 được liên quan đến sự mệt mỏi cơ thể. Nếu thiếu chất dinh dưỡng B1, có thể xuất hiện triệu chứng mệt mỏi và thiếu cảm giác đói. Do đó, cần tiêu thụ liên tục vitamin B1 có tác dụng ngăn ngừa tích lũy các chất độc gây mệt mỏi cơ thể và phục hồi cảm giác đói. Vì lượng vitamin B1 trong thực phẩm thấp, nên nên bổ sung qua các loại thực phẩm chức năng.",
  tips_psi2_food_title: "2. Cẩn trọng khi tiêu thụ caffeine",
  tips_psi2_food_t1:
    "Thay vì tìm kiếm liệu pháp căn bản để chữa trị căng thẳng, nhiều người chỉ sử dụng caffeine như một biện pháp tạm thời. Tuy nhiên, caffeine không thể giải quyết căng thẳng căn bản, dẫn đến việc người sử dụng sẽ tiếp tục tiêu thụ thêm caffeine. Đặc biệt, nếu sử dụng caffeine sau giờ chiều, người dùng có thể không thể ngủ sâu được. Tiêu thụ caffeine theo thói quen có nguy cơ gây nghiện và có thể gây đau đầu, lo âu và trầm cảm nhẹ, vì vậy cần cẩn trọng.",
  tips_psi3_food_title: "3. Đảm bảo đủ nước",
  tips_psi3_food_t1:
    "Nếu sau khi nghỉ đủ mà vẫn cảm thấy mệt mỏi, hãy chú ý uống đủ nước. Nếu thiếu nước, hoạt động trao đổi chất trong cơ thể sẽ bị suy giảm. Nước là nguyên liệu của các chất lỏng trong cơ thể bao gồm cả máu. Tuy nhiên, nếu không uống đủ nước, máu sẽ bị đặc quá và tim phải làm việc nhiều hơn để đẩy máu đến khắp cơ thể, điều này dễ dẫn đến tình trạng mệt mỏi. Hãy uống đủ nước để năng lượng có thể được truyền tải một cách hiệu quả.",
  tips_psi1_rest_t1:
    "Đủ giấc ngủ là rất quan trọng để phục hồi năng lượng và sự tập trung đã giảm. Nên giữ thời gian ngủ và thức giấc đều nhau nếu có thể. Giấc ngủ trưa ngắn giúp giảm mệt mỏi nhưng nếu ngủ quá lâu, sẽ khó để ngủ đủ vào ban đêm, nên nên tránh ngủ trưa quá 30 phút. Khi thực hiện các hoạt động hằng ngày, nên nghỉ ngơi thường xuyên trong một khoảng thời gian ngắn để duy trì cân bằng giữa nghỉ ngơi và hoạt động. Việc nằm lâu trên giường và nghỉ quá nhiều sẽ làm tăng cảm giác yếu đuối, vì vậy nên tránh việc này.",
  tips_msi1_mindset_t1:
    "Nhiều người hành xử hóa giải stress bằng cách hút thuốc lá hoặc gây ồn. Tuy nhiên, khi say rượu, hiệu suất công việc giảm sút và cảm thấy không thoải mái, cuối cùng sức đề kháng giảm và sức khỏe suy yếu. Trong kết quả cuối cùng, việc lệ thuộc vào chất gây nghiện (rượu, thuốc lá, ma túy, v.v.) sẽ trở thành stress tăng thêm. Thay vì lệ thuộc vào chất làm dịu tạm thời, điều tốt nhất là chấp nhận stress một cách tích cực, công nhận khả năng và giới hạn của bản thân, và tìm cách xử lý thích hợp trong đó.",
  tips_msi1_exercise_title: "1. Cơ vai",
  tips_msi1_exercise_t1:
    "- Nâng vai lên càng cao càng tốt và giữ trong 10 giây.",
  tips_msi1_exercise_t2:
    "- Cảm nhận sự căng thẳng quanh vai và cảm giác cơ thể duỗi thẳng lên từ cổ xuống lưng và đầu.",
  tips_msi1_exercise_t3:
    "- Tập trung vào cảm giác khi giảm nhẹ áp lực từ vai xuống trong 20 giây.",
  tips_msi1_exercise_t4: "- Thưởng thức cảm giác thư giãn dễ chịu và sưởi ấm.",
  tips_msi2_exercise_title: "2. Cơ cổ",
  tips_msi2_exercise_t1:
    "- Dựa đầu và lưng vào lưng ghế, giữ cằm sát vào ngực trong vòng 10 giây.",
  tips_msi2_exercise_t2:
    "- Tập trung vào cảm giác như là sự thư giãn lan tỏa từ phía sau cổ đến đầu.",
  tips_msi2_exercise_t3:
    "- Trong vòng 20 giây, thư giãn dần ra và để đầu thoải mái dựa vào lưng ghế.",
  tips_msi2_exercise_t4:
    "- Tận hưởng cảm giác thoải mái khác biệt so với lúc căng thẳng.",
  tips_msi3_exercise_title: "3. Cơ bụng",
  tips_msi3_exercise_t1:
    "- Tạo ra một vùng bụng phẳng và cứng rắn để căng cơ bụng trong 10 giây.",
  tips_msi3_exercise_t2:
    "- Tập trung vào cảm giác như đang kéo dãn cơ bụng lan ra đầu và hệ thống hô hấp của bạn trong khi cơ bụng đang căng thẳng.",
  tips_msi3_exercise_t3:
    "- Thở ra chậm rãi trong 20 giây và thả lỏng cơ bụng bằng cách nới lỏng và giãn cơ bụng.",
  tips_msi3_exercise_t4:
    "- Thở vào và phình lên cơ bụng, sau đó hít thở ra và co cơ bụng.",

  // result
  ////////////////////////////////////////////////////////////

  result_header_test_result: "Kết quả",
  result_healthy_range: "Phạm vi khỏe mạnh",
  result_comment_hr_good:
    "Nhịp tim của bạn là # nhịp/phút, nằm trong # phạm vi khỏe mạnh.",
  result_comment_hr_normal:
    "Nhịp tim của bạn là # nhịp/phút, nằm trong # phạm vi bình thường.",
  result_comment_hr_bad:
    "Nhịp tim của bạn là # nhịp/phút, nằm trong # phạm vi nguy hiểm.",
  result_comment_rep_good:
    "Tốc độ hô hấp của bạn là # lần/phút, nằm trong # phạm vi khỏe mạnh.",
  result_comment_rep_normal:
    "Tốc độ hô hấp của bạn là # lần/phút, nằm trong # phạm vi bình thường.",
  result_comment_rep_bad:
    "Tốc độ hô hấp của bạn là # lần/phút, nằm trong # phạm vi nguy hiểm.",
  result_comment_psi_good:
    "Stress về mặt thể chất của bạn là #, nằm trong # phạm vi khỏe mạnh.",
  result_comment_psi_normal:
    "Stress về mặt thể chất của bạn là #, nằm trong # phạm vi bình thường.",
  result_comment_psi_bad:
    "Stress về mặt thể chất của bạn là #, nằm trong # phạm vi nguy hiểm.",
  result_comment_msi_good:
    "Stress về mặt tinh thần của bạn là #, nằm trong # phạm vi khỏe mạnh.",
  result_comment_msi_normal:
    "Stress về mặt tinh thần của bạn là #, nằm trong # phạm vi bình thường.",
  result_comment_msi_bad:
    "Stress về mặt tinh thần của bạn là #, nằm trong # phạm vi nguy hiểm.",
};
