import styled from "styled-components";

export const Container = styled.div`
  /* width: 920px; */
  /* height: 160px; */
  width: 100%;
  padding: 0 4.166666666666667vh;
  max-width: 920px;
  height: 8.333333333333334vh;
  max-height: 160px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ArrowContainer = styled.div`
  /* width: 160px; */
  /* height: 160px; */
  width: 8.333333333333334vh;
  max-width: 160px;
  height: 8.333333333333334vh;
  max-height: 160px;
  border-radius: 2.0833333333333335vh;
  background-color: #666;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const PageCountContainer = styled.div`
  /* width: 520px; */
  /* height: 160px; */
  width: 27.083333333333336vh;
  max-width: 520px;
  height: 8.333333333333334vh;
  max-height: 160px;
  border: 0.052083333333333336vh solid #fff;
  border-radius: 2.0833333333333335vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
