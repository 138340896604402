import { memo } from "react";
import HrTipTemplate from "../components/templates/HrTipTemplate";
import useTap from "../hooks/useTap";

const HrTipPage = () => {
  const {
    ref,
    width,
    currentIdx,
    transX,
    bodyArr,
    tipsIndex,
    changeTap,
    handleDragChange,
    handleDragEnd,
    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    contentIdxPlus,
    contentIdxMinus,
  } = useTap();

  return (
    <HrTipTemplate
      ref={ref}
      width={width}
      currentIdx={currentIdx}
      transX={transX}
      bodyArr={bodyArr}
      tipsIndex={tipsIndex}
      changeTap={changeTap}
      handleDragChange={handleDragChange}
      handleDragEnd={handleDragEnd}
      handleTouchStart={handleTouchStart}
      handleTouchMove={handleTouchMove}
      handleTouchEnd={handleTouchEnd}
      contentIdxPlus={contentIdxPlus}
      contentIdxMinus={contentIdxMinus}
    />
  );
};

export default memo(HrTipPage);
