import React from "react";
import { useRecoilValue } from "recoil";
import { standardRange } from "../../../enum/enum";
import { useResult } from "../../../hooks/useResult";
import { langState } from "../../../store/localization";
import { percentFc } from "../../../utils/resultData";
import ResultItem from "../ResultItem/ResultItem";
import { ResultBodyContainer } from "./styles";

const healthData = ["hr", "rep", "psi", "msi"];

const ResultBody = () => {
  const lang = useRecoilValue(langState);
  const { result, EtitleComment, circleValueData } = useResult();

  return (
    <ResultBodyContainer>
      {healthData.map((item: string, i: number) => {
        return (
          <ResultItem
            key={i}
            content={EtitleComment[`title_${lang.lang}`][item][item]}
            dataValue={Number(result[item][item])}
            rangeMin={percentFc(
              result[item]["min"],
              standardRange[item].min,
              standardRange[item].max
            )}
            rangeMax={percentFc(
              result[item]["max"],
              standardRange[item].min,
              standardRange[item].max
            )}
            comment={EtitleComment["comment"][item][result[item].status]}
            circleValue={String(circleValueData[item])}
            status={item === "hr" ? "normal" : String(result[item]["status"])}
            // status={String(result[item]["status"])}
          />
        );
      })}
    </ResultBodyContainer>
  );
};

export default React.memo(ResultBody);
