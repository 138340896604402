import React, { memo } from "react";
import Button from "../../atoms/Button";
import {
  Container,
  TapContainer,
  TapListContainer,
  TapTwoContainer,
  TapUnderLine,
} from "./styles";

export interface ITwoTap {
  title: string[];
  currentIdx: number;
  changeTap?: (e: React.MouseEvent | React.TouchEvent) => void;
}

const TwoTap = ({ title, currentIdx, changeTap }: ITwoTap) => {
  return (
    <Container>
      <TapTwoContainer>
        <TapContainer
          id="exercise"
          onClick={changeTap}
          onTouchStart={() => changeTap}
        >
          <Button
            id="exercise"
            content={title[0]}
            fontSize="2.604166666666667vh"
            fontWeight="900"
            backgroundColor="transparent"
            color={currentIdx === 0 ? "#fff" : "#666"}
          />
        </TapContainer>
        <TapContainer id="food" onClick={changeTap}>
          <Button
            id="food"
            content={title[1]}
            fontSize="2.604166666666667vh"
            fontWeight="900"
            backgroundColor="transparent"
            color={currentIdx === 1 ? "#fff" : "#666"}
          />
        </TapContainer>
      </TapTwoContainer>
      <TapListContainer>
        <TapUnderLine active={currentIdx === 1} />
      </TapListContainer>
    </Container>
  );
};

export default memo(TwoTap);
