import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const CenterContainer = styled.div`
  /* width: 1080px; */
  /* height: 1920px; */
  width: 100vw;
  height: 100vh;
  background-color: #000;
`;
