// export const EtitleComment: {
//   [index: string]: { [index: string]: { [index: string]: string } };
// } = {
//   title: {
//     hr: { hr: "와 비교한 심박수" },
//     rep: { rep: "와 비교한 호흡수" },
//     psi: { psi: "와 비교한 육체적 스트레스 지수" },
//     msi: { msi: "와 비교한 정신적 스트레스 지수" },
//   },
//   comment: {
//     hr: {
//       good: "측정인의 현재 심박수는 ,회/분으로 정상범위에 있는 ,건강한 상태,입니다.",
//       normal:
//         "측정인의 현재 심박수는 ,회/분으로 정상범위에 있는 ,평범한 상태,입니다.",
//       bad: "측정인의 현재 심박수는 ,회/분으로 위험범위에 있는 ,위험한 상태,입니다.",
//     },
//     rep: {
//       good: "측정인의 현재 호흡수는 ,회/분으로 정상범위에 있는 ,건강한 상태,입니다.",
//       normal:
//         "측정인의 현재 호흡수는 ,회/분으로 정상범위에 있는 ,평범한 상태,입니다.",
//       bad: "측정인의 현재 호흡수는 ,회/분으로 위험범위에 있는 ,위험한 상태,입니다.",
//     },
//     psi: {
//       good: "측정인의 현재 육체적 스트레스 지수는 ,으로 정상범위에 있는 ,건강한 상태,입니다.",
//       normal:
//         "측정인의 현재 육체적 스트레스 지수는 ,으로 정상범위에 있는 ,평범한 상태,입니다.",
//       bad: "측정인의 현재 육체적 스트레스 지수는 ,으로 위험범위에 있는 ,위험한 상태,입니다.",
//     },
//     msi: {
//       good: "측정인의 현재 정신적 스트레스 지수는 ,으로 정상범위에 있는 ,건강한 상태,입니다.",
//       normal:
//         "측정인의 현재 정신적 스트레스 지수는 ,으로 정상범위에 있는 ,평범한 상태,입니다.",
//       bad: "측정인의 현재 정신적 스트레스 지수는 ,으로 위험범위에 있는 ,위험한 상태,입니다.",
//     },
//   },
// };

export const standardRange: { [index: string]: { [index: string]: number } } = {
  hr: { min: 40, max: 120 },
  rep: { min: 4, max: 40 },
  psi: { min: 0, max: 100 },
  msi: { min: 0, max: 100 },
};
