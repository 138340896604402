import { useLocation } from "react-router-dom";
import { TQueryData } from "../type/queryData";
import { TResultData } from "../type/resultData.type";
import { calcSi, calcRep, percentFc } from "../utils/resultData";
import { calcHr } from "../utils/resultHr";
import { useLocalization } from "./useLocalization";

export const useResult = () => {
  const urlItem = ["gender", "age", "hr", "rep", "psi", "msi"];
  const location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const translation = useLocalization();

  let queryData: TQueryData = {
    age: 0,
    gender: 0,
    hr: 0,
    rep: 0,
    psi: 0,
    msi: 0,
  };

  // url의 값을 key value 형태로 만든다.
  urlItem.forEach(async (item) => {
    const value = params.get(item);
    queryData = Object.assign(queryData, { [item]: value });
  });

  // hr값과 min, max를 Return하는 함수
  const resultHr = calcHr({
    age: queryData.age,
    gender: queryData.gender,
    numHr: Number(queryData.hr),
  });

  const resultRep = calcRep(queryData.rep);
  const resultPsi = calcSi(queryData.psi, "psi");
  const resultMsi = calcSi(queryData.msi, "msi");

  const result: TResultData = {
    hr: resultHr,
    rep: { ...resultRep, min: 6, max: 18 },
    psi: { ...resultPsi, min: 33, max: 66 },
    msi: { ...resultMsi, min: 33, max: 66 },
    age: { age: queryData.age },
  };
  if (result.age.age < 10) result.age.age = 1;
  const ageRange = result.age.age.toString().split("")[0] + "0";

  // 상태에 따른 코멘트
  const EtitleComment: {
    [index: string]: { [index: string]: { [index: string]: string } };
  } = {
    title_ko: {
      hr: {
        hr: `${ageRange}대와 비교한 휴식기 심박수`,
      },
      rep: { rep: `${ageRange}대와 비교한 호흡수` },
      psi: { psi: `${ageRange}대와 비교한 육체적 스트레스 지수` },
      msi: { msi: `${ageRange}대와 비교한 정신적 스트레스 지수` },
    },
    title_en: {
      hr: {
        hr: `Resting Heart rate (Age group ${ageRange}’s)`,
      },
      rep: { rep: `Respiration rate (Age group ${ageRange}’s)` },
      psi: { psi: `Physical Stress Index (Age group ${ageRange}’s)` },
      msi: { msi: `Mental Stress Index (Age group ${ageRange}’s)` },
    },
    title_vi: {
      hr: {
        hr: `Nhịp tim ngừng đập (Tuổi ${ageRange})`,
      },
      rep: { rep: `Tốc độ hô hấp (Tuổi ${ageRange})` },
      psi: { psi: `Stress về mặt thể chất (Tuổi ${ageRange})` },
      msi: { msi: `Stress về mặt tinh thần (Tuổi ${ageRange})` },
    },
    comment: {
      hr: {
        good: translation.result_comment_hr_good,
        normal: translation.result_comment_hr_normal,
        bad: translation.result_comment_hr_bad,
      },
      rep: {
        good: translation.result_comment_rep_good,
        normal: translation.result_comment_rep_normal,
        bad: translation.result_comment_rep_bad,
      },
      psi: {
        good: translation.result_comment_psi_good,
        normal: translation.result_comment_psi_normal,
        bad: translation.result_comment_psi_bad,
      },
      msi: {
        good: translation.result_comment_msi_good,
        normal: translation.result_comment_msi_normal,
        bad: translation.result_comment_msi_bad,
      },
    },
  };

  // 원의 값
  const circleValueData: { [index: string]: number | string | undefined } = {
    hr: percentFc(result.hr.hr, 40, 120),
    rep: percentFc(result.rep.rep, 4, 40),
    psi: result.psi.psi,
    msi: result.msi.msi,
  };

  return {
    result,
    EtitleComment,
    circleValueData,
  };
};
