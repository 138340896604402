// export const avgHr: { [index: string]: { value: number; range: number } } = {
//   M0_HR_Min_10: { value: 69, range: 3 },
//   M0_HR_Max_10: { value: 73, range: 7 },
//   M0_HR_Min_20: { value: 70, range: 3 },
//   M0_HR_Max_20: { value: 74, range: 6 },
//   M0_HR_Min_30: { value: 70, range: 3 },
//   M0_HR_Max_30: { value: 75, range: 6 },
//   M0_HR_Min_40: { value: 71, range: 3 },
//   M0_HR_Max_40: { value: 76, range: 6 },
//   M0_HR_Min_50: { value: 71, range: 3 },
//   M0_HR_Max_50: { value: 75, range: 5 },
//   M0_HR_Min_60: { value: 69, range: 3 },
//   M0_HR_Max_60: { value: 73, range: 5 },
//   M1_HR_Min_10: { value: 73, range: 3 },
//   M1_HR_Max_10: { value: 78, range: 5 },
//   M1_HR_Min_20: { value: 72, range: 3 },
//   M1_HR_Max_20: { value: 76, range: 4 },
//   M1_HR_Min_30: { value: 73, range: 3 },
//   M1_HR_Max_30: { value: 78, range: 6 },
//   M1_HR_Min_40: { value: 73, range: 3 },
//   M1_HR_Max_40: { value: 77, range: 6 },
//   M1_HR_Min_50: { value: 73, range: 4 },
//   M1_HR_Max_50: { value: 77, range: 6 },
//   M1_HR_Min_60: { value: 72, range: 3 },
//   M1_HR_Max_60: { value: 76, range: 8 },
// };

export const calcHr = (mokData: {
  age: number;
  gender: number;
  numHr: number;
}) => {
  let hr = mokData.numHr;
  if (40 >= hr) hr = 40;
  if (hr >= 120) hr = 120;
  if (mokData.age < 10) mokData.age = 1;
  let status = "normal";
  if (61 <= hr && hr <= 99) {
    status = "good";
  } else if (60 >= hr) {
    status = "normal";
  } else {
    status = "bad";
  }
  // const avg = {
  //   min: avgHr[`M${gender}_HR_Min_${age}`],
  //   max: avgHr[`M${gender}_HR_Max_${age}`],
  // };
  // let status = "normal";
  // if (avg.min.value <= hr && hr <= avg.max.value) {
  //   status = "good";
  // } else if (
  //   avg.min.value - avg.min.range <= hr &&
  //   hr <= avg.max.value + avg.max.range
  // ) {
  //   status = "normal";
  // } else {
  //   status = "bad";
  // }
  return { hr: hr, status: status, min: 61, max: 99 };
};
