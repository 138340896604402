import styled from "styled-components";

export const ResultBodyContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 0 27px; */
  margin-bottom: 2.5vh;
  /* margin-bottom: 20px; */
  margin-top: 5.5vh;
  /* margin-top: 44px; */
`;
