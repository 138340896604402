import styled from "styled-components";

export const Container = styled.div`
  /* padding: 80px; */
  width: 100vw;
  padding: 4.166666666666667vh;
  max-height: 1360px;
  height: 70.83333333333334vh;
  display: flex;
  flex-direction: column;
  /* background-color: red; */
`;

export const Img = styled.img`
  /* width: 920px; */
  /* border-radius: 40px; */
  width: 100%;
  max-width: 920px;
  border-radius: 2.0833333333333335vh;
  border: 0.052083333333333336vh solid #fff;
  object-fit: contain;
  flex: none;
  user-select: none;
`;

export const TextContainer = styled.div`
  width: 100%;
  max-height: 22.916666666666668vh;
  height: 22.916666666666668vh;
  padding-top: 4.166666666666667vh;
`;
