import React from "react";
import ResultBody from "../../molecules/ResultBody/ResultBody";
import ResultHeader from "../../molecules/ResultHeader/ResultHeader";
import { ResultTemplateContainer } from "./styles";

const ResultTemplate = () => {
  return (
    <ResultTemplateContainer>
      <ResultHeader />
      <ResultBody />
    </ResultTemplateContainer>
  );
};

export default React.memo(ResultTemplate);
