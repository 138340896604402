import { Container, Img, TextContainer } from "./styles";
import Text from "../../atoms/Text/index";
import { TTapBody } from "../../../type/tapBody.type";

export interface ICarousel {
  currentIdx: number;
  body: TTapBody;
}

const Carousel = ({ currentIdx, body }: ICarousel) => {
  return (
    <Container>
      <Img
        src={body.img}
        alt={String(currentIdx)}
        loading="lazy"
        decoding="async"
        draggable={false}
      />
      <TextContainer>
        <Text
          content={body.title}
          fontSize="1.5625vh"
          fontWeight="700"
          customStyle={{
            letterSpacing: "-0.052083333333333336vh",
            lineHeight: "180%",
          }}
        />
        <Text
          content={body.text1}
          fontSize="1.5625vh"
          fontWeight="400"
          customStyle={{
            letterSpacing: "-0.052083333333333336vh",
            lineHeight: "180%",
          }}
        />
        {body.enter === 1 && (
          <Text content="3" fontSize="1.5625vh" fontWeight="400" opacity="0" />
        )}
        <Text
          content={body.text2}
          fontSize="1.5625vh"
          fontWeight="400"
          customStyle={{
            letterSpacing: "-0.052083333333333336vh",
            lineHeight: "180%",
          }}
        />
        <Text
          content={body.text3}
          fontSize="1.5625vh"
          fontWeight="400"
          customStyle={{
            letterSpacing: "-0.052083333333333336vh",
            lineHeight: "180%",
          }}
        />
        <Text
          content={body.text4}
          fontSize="1.5625vh"
          fontWeight="400"
          customStyle={{
            letterSpacing: "-0.052083333333333336vh",
            lineHeight: "180%",
          }}
        />
      </TextContainer>
    </Container>
  );
};

export default Carousel;
